.section__padding {
  padding: 1rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url('./assets/bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__foo {
  background: url('./assets/footer.png');
  background-position: flex-start;
  background-size: cover;

  background-attachment: fixed;
}

.app__wrapper {
  display: grid;
  grid-template-columns: 2.5fr 2fr;
  justify-content: space-between;
  align-items: center;
}

.app__wrapper_info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 1.4rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 100%;
  transform: scaleX(-1);
}

.button_slide {
  background-color: #0C0C0C;
  color: var(--color-green);
  border: 2px solid #b4ff00;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  padding: 0.7rem 1.2rem;
  display: inline-block;
  border-radius: 3px;
  font-family: var(--font-alt);
  font-size: 19px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #b4ff00;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_right:hover {
  box-shadow: inset 400px 0 0 0 #b4ff00;
  color: var(--color-black);
}

.button_slide_purple {
  background-color: #0C0C0C;
  color: var(--color-purple);
  border: 2px solid #d382ff;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  padding: 0.7rem 1.2rem;
  display: inline-block;
  border-radius: 3px;
  font-family: var(--font-alt);
  font-size: 19px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #d382ff;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_right_purple:hover {
  box-shadow: inset 400px 0 0 0 #d382ff;
  color: var(--color-black);
}


.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: none;
  line-height: 28px;
  font-size: 20px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 25px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1366px) {
  .section__padding {
    padding: 1rem 6rem;
  }
}

@media screen and (max-width: 1150px) {

  .app__header-h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .app__header-h2 {
    font-size: 45px;
    line-height: 45px;
  }
  
  .app__wrapper {
    grid-template-columns: 1fr;
    justify-content: center;
    grid-template-areas: "img";
  }

  .app__wrapper_img {
    margin: 2rem 0 0 0;
    grid-area: img;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }

  .app__wrapper_info {
    margin: 2rem 0 0 0;
  }

  .app__bg {
    background: var(--color-black);
  }


}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 2rem;
  }
  .app__wrapper_img {
    margin-bottom: 2rem;
  }
  
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 1rem 2rem;
  }

  .app__header-h1 {
    font-size: 45px;
    line-height: 45px;
  }

  .app__header-h2 {
    font-size: 35px;
    line-height: 40px;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 17px;
  }

  .app__wrapper_img {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
