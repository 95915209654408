.app__footer {
    width: 100%;
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding-top: 0;
}

.app__footer img {
    position: relative;
    width: 100%;
    z-index: 1;

}
.app__footer-links {
    width: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 36rem;

}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1;
    margin: 1rem;
    text-align: center;
}

.app__footer-links_logo img:first-child {
    width: 340px;
    margin-bottom: 1rem;
}


.app__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 0.5rem;
}

.app__footer-links_icons {
    margin-top: 1rem;
}

.app__footer-links_icons svg {
    color: var(--color-black);
    font-size: 32px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: var(--color-aqua);
}

.app__footer-headtext {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.footer__copyright {
    margin-top: 3.5rem;

}

.footer__copyright p {
    color: white;
}

.footer__copyright_b {
    margin-top: 3.5rem;
}

.footer__copyright_b p{
    color: white;
}


@media screen and (min-width: 2000px) {



    .app__footer-headtext {
        font-size: 44px;
    }


}


@media screen and (max-width: 1920px) {
    .app__footer-links {
        margin-top: 56rem;
    }

    .footer__copyright {
        margin-top: 5rem;
    }

    .app__footer-links_logo img:first-child  {
        width: 430px;
    }
    
    .footer__copyright_b {
        display: none;
    }
}

@media screen and (max-width: 1366px) {
    .app__footer-links {
        margin-top: 36rem;
    }

    .footer__copyright {
        margin-top: 3.5rem;
    }

    .app__footer-links_logo img:first-child  {
        width: 340px;
    }

    .footer__copyright_b {
        display: none;
    }
}



@media screen and (max-width: 1365px) {
    .footer__background {
        display: none;
    }

    .app__footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
        margin: 0 0 0 0;
        background-color: var(--color-purple);
    }

    .footer__copyright {
        display: none;
    }

    .footer__copyright_b {
        display: block;
    }
}

@media screen and (max-width: 1150px) {
    .app__footer-links_contact,
    .app__footer-links_logo,
    .app__footer-links_work {
        margin: 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__footer {
        padding: 0 1rem 2rem 1rem;
    }

    .app__footer-links {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 350px) {
    .app__footer-links_logo img:first-child {
        width: 80%;
    }
}