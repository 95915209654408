.app__aboutus {
    position: relative;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_brain {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-right: 2rem;
}

.app__aboutus-content_brain img {
    width: 45%;
}

.app__aboutus-content_hand {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 1rem;
}

.app__aboutus-content_hand img {
    width: 60%;
}

.app__aboutus-content_clock {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-left: 2rem;
}

.app__aboutus-content_clock img {
    width: 30%;
}

.app__aboutus-content_clock p,
.app__aboutus-content_hand p,
.app__aboutus-content_brain p {
    margin: 3rem 0;
    color: var(--color-grey);
}

@media screen and (max-width: 2000px) {
    .app__aboutus-content_clock p,
    .app__aboutus-content_hand p,
    .app__aboutus-content_brain p {
        margin: 1.5rem 0;
        color: var(--color-grey);
        font-size: 24px;
    }

    .app__aboutus-content_clock img {
        width: 32%;
    }
}

@media screen and (max-width: 900px)  {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_clock ,
    .app__aboutus-content_hand ,
    .app__aboutus-content_brain  {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 2rem 0rem;
    }

    .app__aboutus-content_clock p,
    .app__aboutus-content_hand p,
    .app__aboutus-content_brain p {
        margin: 3rem 0;
        font-size: 28px;
    }

    .app__aboutus-content_clock img {
        width: 45%;
    }
}

@media screen and (max-width: 650px)  {
   .app__aboutus-content_clock ,
   .app__aboutus-content_hand ,
   .app__aboutus-content_brain {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3rem 0rem;
   }

    .app__aboutus-content_clock p,
    .app__aboutus-content_hand p,
    .app__aboutus-content_brain p {
        margin: 2rem 0;
        font-size: 21px;
    }

    .app__aboutus-content_clock img {
        width: 30%;
    }
}
