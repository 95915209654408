.app__header {
    background-color: var(--color-black);
}

.app__header-h1 {
    font-family: var(--font-alt);
    color: var(--color-white);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 65px;
    font-size: 65px;
    
}

.app__header-h2 {
    font-family: var(--font-alt);
    color: var(--color-pink);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 50px;
    font-size: 50px;
}

.app__wrapper_info p {
    color: var(--color-grey);
    max-width: 600px;
}

  
