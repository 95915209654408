.app__services-title  h1{
    max-width: 950px;
    color: var(--color-purple);
}

.app__wrapper-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.app__chef_img {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 8rem 0 0 0;
    
}

.marquee_padding {
    padding: 1rem 0 7rem 0;
}

.app__chef_img img{
    width: 80%;
    transform: rotate(10deg);
}

.rectange {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pink);
    transform: rotate(-4deg);
    width: 99.9%;
    height: 70px;
}

.marquee__text {
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: none;
    font-size: 30px;    
}


@media screen and (max-width: 2000px) {
    .rectange {
        margin:  3rem 0 0 0;
    }

    .app__chef_img {
        justify-content: flex-start;      
    }
}

@media screen and (max-width: 1366px) {
    .app__chef_img {
        justify-content: flex-end; 
        margin: 11rem 0 0 0;
    }

    .rectange {
        width: 99.9%;
    }
}

@media screen and (max-width: 1200px) {
    .app__wrapper-section {
        flex-direction: column;
    }

    .app__services-title  h1{
        font-size: 60px;
        line-height: 60px;
    } 

    .app__chef_img img{
        width: 60%;
    }

    .app__chef_img {
        justify-content: center;
        margin: 1rem 0 0 6rem;
    }

}

@media screen and (max-width: 950px)  {
    .rectange {
        width: 99.8%;
    }
}

@media screen and (max-width: 650px)  {
    .app__services-title  h1{
        font-size: 45px;
        line-height: 45px;
    } 

    .app__chef_img {
        margin: 1rem 0 0 0;
    }

    .marquee__text {
        font-weight: 300;
        font-size: 23px;    
    }

    .rectange {
        height: 50px;
        margin: 1rem 0 0 0;
    }
    
    .marquee_padding {
        padding: 1rem 0 2rem 0;
    }
}