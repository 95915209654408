.app__bg_black {
    background-color: var(--color-black);
}

.app__wrapper-section_intro {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

.atom {
	position: relative;
	display: flex;
	width: 450px;
	height: 450px;
	align-items: center;
	justify-content: center;

}

.atom::before {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	background: transparent;
	box-shadow: inset 0 0 10px #fff;
	border-radius: 50%;
}

.line {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.7;
}

.line-1 {
  --color: #b4ff00;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border-bottom: 10px solid var(--color);
	border-top: 10px solid var(--color);
	animation: line1 2s linear infinite;
  filter:drop-shadow( 0 0 10px var(--color));
}

@keyframes line1 {
	0% {
		transform: rotateY(70deg) rotateZ(0deg);
	}
	100% {
		transform: rotateY(70deg) rotateZ(360deg);
	}
}

.line-2 {
  --color: #d382ff;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border-right: 10px solid var(--color);
	border-left: 10px solid var(--color);
	animation: line2 3s linear infinite;
  filter:drop-shadow( 0 0 10px var(--color));
}

@keyframes line2 {
	0% {
		transform: rotateX(80deg) rotateY(25deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(80deg) rotateY(25deg) rotateZ(360deg);
	}
}

.line-3 {
  --color: #6afdf8;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border-right: 10px solid var(--color);
	border-left: 10px solid var(--color);
	animation: line3 3s linear infinite;
  filter:drop-shadow( 0 0 10px var(--color));
}

@keyframes line3 {
	0% {
		transform: rotateX(-80deg) rotateY(25deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(-80deg) rotateY(25deg) rotateZ(360deg);
	}
}


@media screen and (max-width: 2000px) {
    .atom {
		display: flex;
		width: 500px;
		height: 500px;

	}

	.app__wrapper-section_intro {
		display: grid;
		grid-template-columns: 2.7fr 1fr;
		justify-content: space-between;
		align-items: center;
	}
}


@media screen and (max-width: 1366px) {
	.atom {
		width: 400px;
		height: 400px;	
	}	
}

@media screen and (max-width: 1150px) {
	.app__wrapper-section_intro {
		grid-template-columns: 1fr;
		justify-content: center;
		grid-template-areas: "img";
	}	

	.atom {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 400px;
		height: 400px;
		margin: 1rem 0 2rem 12rem;
		grid-area: img;
	}	
}

@media screen and (max-width: 950px) {
	.atom {
		margin: 1rem 0 1rem 10rem;
	}
}


@media screen and (max-width: 800px) {
	.atom {
		width: 350px;
		height: 350px;
		margin: 0 0 1rem 9rem;
	}
}

@media screen and (max-width: 650px)  {
	.atom {
		width: 300px;
		height: 300px;
		margin: 1rem 0 1rem 3rem;
	}
}
