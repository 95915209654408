.app__specialMenu {
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-tittle {
    background: var(--color-black);
    padding-top: 2rem;
}

.app__specialMenu-tittle h1 {
    max-width: 800px;
}

.button__side {
    display: flex;
    justify-content: flex-end;
}

.app__section1 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;
}

.app__section1 p {
    max-width: 200px;
    color: var(--color-grey);
    margin-left: 2rem;
}

.app__section1 img {
    width: 23%;
}

.app__section2 {
    display: flex;
    justify-content: center;
    margin-right: 3rem;
}

.app__section3 {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.app__section3 p {
    max-width: 240px;
    color: var(--color-grey);
    margin-left: 2rem;
}

.app__section3 img {
    width: 22%;
}

.app__section4 {
    display: flex;
    justify-content: center;
    margin-right: 3rem;
}

.app__section5 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}

.app__section5 p {
    max-width: 230px;
    color: var(--color-grey);
    margin-left: 2rem;
}

.app__section5 img {
    width: 21%;
}


@media screen and (max-width: 2000px) {
    .app__section1 p,
    .app__section3 p,
    .app__section5 p {
        font-size: 22px;
    }
}

@media screen and (max-width: 1150px) {
    .app__section1 p,
    .app__section3 p,
    .app__section5 p {
        font-size: 20px;
    }

    .app__section1 img,
    .app__section3 img,
    .app__section5 img {
        width: 23%;
    }

    .app__section2 img,
    .app__section4 img {
        width: 80%;
        margin-top: 1.2rem;
        margin-bottom: 1rem;
    }

    .app__header-h1 {
        font-size: 60px;
        line-height: 60px;
    }

}

@media screen and (max-width: 900px)  {
    .app__specialMenu {
        flex-direction: column;
    }

    .app__section1 ,
    .app__section3 ,
    .app__section5 {
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     text-align: start;
     margin-top: 6rem;
    }
 
    .app__section1 p,
    .app__section3 p,
    .app__section5 p {
        font-size: 22px;
        margin-top: 2rem;
        max-width: 350px;
    }
 
    .app__section1 img,
    .app__section3 img,
    .app__section5 img {
        width: 53%;
    }
 
    .app__section2 img,
    .app__section4 img {
     display: none;
    }
}

@media screen and (max-width: 650px)  {
   .app__section1 ,
   .app__section3 ,
   .app__section5 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: start;
    margin-top: 5rem;
   }

   .app__section1 p,
   .app__section3 p,
   .app__section5 p {
       font-size: 19px;
       margin-top: 2rem;
       max-width: 260px;
   }

   .app__section1 img,
   .app__section3 img,
   .app__section5 img {
       width: 60%;
   }

   .app__section2 img,
   .app__section4 img {
    display: none;
   }

}