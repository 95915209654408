.section__padding_form {
  padding: 4rem 6rem;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 1000px;
  border: 2px solid #d382ff;
  border-radius: 14px;
  height: 600px;
  background-color: var(--color-black);
}


.form-content-left {
  border-radius: 10px 0 0 10px;
  position: relative;
}

.form-img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-img-2 {
  width: 55%;
  height: 55%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-success {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: var(--color-purple);
  font-family: var(--font-alt);
  font-size: 36px;
  margin-top: 40%;
}

.form-content-right {
  border-radius: 0 10px 10px 0;
  position: relative;
  background: var(--color-black);
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h1 {
  font-size: 4rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: var(--color-purple);
  font-family: var(--font-alt);
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
  font-family: var(--font-alt);

}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
  
}

.form-label {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 6px;
  color: #fff;
  
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none; 
  height: 40px;
  width: 100%;
  color: #d382ff;
  background-color: var(--color-black);
  border: 2px solid #d382ff;
  border-radius: 5px;
  font-family: var(--font-alt);
  font-size: 16px;
}

.form-input::placeholder {
  color: #595959;
  font-size: 14px;
  font-family: var(--font-alt);
  text-align: start;
}

.button_slide_aqua {
  background-color: #0C0C0C;
  color: var(--color-aqua);
  border: 2px solid #6afdf8;
  letter-spacing: 0.04em;
  padding: 0.7rem 1.2rem;
  display: block;
  border-radius: 5px;
  font-family: var(--font-alt);
  font-size: 17px;
  margin-top: 1rem;
  width: 80%;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #6afdf8;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_right_aqua:hover {
  box-shadow: inset 800px 0 0 0 #6afdf8;
  color: var(--color-black);
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

@media screen and (max-width: 2000px) {

  .form h1 {
    font-size: 6rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
  }
  
  .form-container {
    width: 1400px;
    height: 800px;
  }

  .form-content-right {
    margin-bottom: 0rem;
  }

  .form-label {
    font-size: 1.6rem;
  }

  .form-input {
    height: 55px;
    font-size: 1.3rem;
  }

  .form-input::placeholder {
    font-size: 1.2rem;    
  }

  .button_slide_aqua {
    font-size: 1.3rem;
  }

  .form-inputs p {
    font-size: 1.2rem;    
  }
}

@media screen and (max-width: 1380px) {

  .form h1 {
    font-size: 3.5rem;
  }

  
  .form-container {
    width: 1100px;
    height: 620px;
  }

  .form-content-right {
    margin-bottom: 0rem;
  }

  .form-label {
    font-size: 1.2rem;
  }

  .form-input {
    height: 45px;
    font-size: 1.2rem;
  }

  .form-input::placeholder {
    font-size: 1rem;    
  }

  .button_slide_aqua {
    font-size: 1.2rem;
  }

  .form-inputs p {
    font-size: 0.9rem;    
  }
}



@media screen and (max-width: 1210px) {

  .form h1 {
    font-size: 4rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
  }

  
  .form-container {
    grid-template-columns: 1fr;
    justify-content: center;
    grid-template-areas: "img";
    width: 700px;
    height: 1050px;
  }

  .form-content-left {
    grid-area: img;
  }


  .form-content-right {
    margin-bottom: 5rem;
  }

  .form-label {
    font-size: 1.25rem;
  }

  .form-input {
    height: 50px;
    font-size: 1.2rem;
  }

  .form-input::placeholder {
    font-size: 1rem;    
  }

  .button_slide_aqua {
    font-size: 1.2rem;
  }

  .form-inputs p {
    font-size: 1rem;    
  }
}

@media screen and (max-width: 650px) {

  .form h1 {
    font-size: 3.5rem;
    margin-bottom: 0.8rem;
  }

  
  .form-container {
    width: 700px;
    height: 1020px;
  }


  .form-content-left img {
    width: 65%;
    height: 65%;
    top: 40%;
  }


  .form-content-right {
    margin-bottom: 8rem;
  }

  .form-label {
    font-size: 1.1rem;
  }

  .form-input {
    height: 45px;
    font-size: 1rem;
  }

  .form-input::placeholder {
    font-size: 0.9rem;    
  }

  .button_slide_aqua {
    font-size: 1.1rem;
  }

  .form-inputs p {
    font-size: 0.9rem;    
  }
}

@media screen and (max-width: 560px) {

  .form h1 {
    font-size: 2.9rem;
    margin-bottom: 0.6rem;
  }

  
  .form-container {
    width: 600px;
    height: 850px;
  }


  .form-content-left img {
    width: 65%;
    height: 65%;
    top: 40%;
  }


  .form-content-right {
    margin-bottom: 7rem;
  }

  .form-label {
    font-size: 1rem;
  }

  .form-input {
    height: 40px;
    font-size: 0.9rem;
  }

  .form-input::placeholder {
    font-size: 0.8rem;    
  }

  .button_slide_aqua {
    font-size: 1rem;
  }

  .form-inputs p {
    font-size: 0.8rem;    
  }

  .form-success {
    font-size: 22px;
    margin-top: 20%;
  }
}

@media screen and (max-width: 500px) {

  .form h1 {
    font-size: 2.3rem;
    margin-bottom: 0.4rem;
  }

  
  .form-container {
    width: 600px;
    height: 750px;
  }


  .form-content-left img {
    width: 60%;
    height: 60%;
    top: 35%;
  }


  .form-content-right {
    margin-bottom: 7rem;
  }

  .form-label {
    font-size: 0.9rem;
  }

  .form-input {
    height: 35px;
    font-size: 0.7rem;
  }

  .form-input::placeholder {
    font-size: 0.6rem;    
  }

  .button_slide_aqua {
    font-size: 0.9rem;
  }

  .form-inputs p {
    font-size: 0.7rem;    
  }
}

@media screen and (max-width: 450px) {
  .section__padding_form {
    padding: 3rem 2rem;
  }
}

@media screen and (max-width: 290px) {

  .form h1 {
    font-size: 2rem;
    margin-bottom: 0.4rem;
  }
}
